img{
	display:none;
}
img[src] {
	display: block;
  } 

.content.explore
{
    padding-top:80px;
	display:flex;
}

.explore_left 
{
	min-width:200px;
	padding-right:40px;
	margin-top: 30px;
}
.explore_right 
{
	padding:30px 0 90px 0;
	flex:1;
}
.explore_filter 
{
	margin-bottom:15px;
}
.explore_filter select 
{
	background:var(--background);
	padding:0 0 5px 0;
}
.explore .filters_section
{
    border-radius:10px;
    position:relative;
    display:flex;
    gap:20px;
}
.filters_section input, .filters_section select 
{
    margin-bottom:0;
	background:var(--nav-background);
    color:var(--titlecolor);
	padding:4px 10px 4px 0;
	border:none;
	border-bottom:1px solid var(--forecolor);
}
.filter_col 
{
    amin-width:200px;
}
.filter_col:first-child
{
    flex:1;
}
.items_section
{
    amargin-top:30px;
    amargin-bottom:100px;
    
}
.items_grid
{
    display:flex;
    flex-wrap: wrap;
    margin-left:-10px;
    margin-right:-10px;
}
.explore_item
{
    min-width:33.33%;
	max-width:33.33%;
	width:33.33%;
	padding:12px 10px;
}

.explore_authors 
{
	display:flex;
	gap:20px;
	margin-bottom:30px;
}
.explore_author_item 
{
	width:70px;
	height:70px;
	position:relative;
}
.explore_author_wrapper 
{
	width:100%;
	padding-top: 100%;
	position:relative;
	overflow: hidden;
	border-radius:100%;
	background:var(--item-background);
}
.explore_author_picture 
{
	position:absolute;
	left:0;
	top:0;
	width:100%;
	height:100%;
	object-fit: cover;
	border-radius:100%;
	transition: all 0.35s;
}

.explore_author_item:hover .explore_author_picture
{
	transform:scale(1.1);
}
.explore_author_name 
{
	font-size:0.7em;
	display:none;
	position:absolute;
	left:50%;
	top:-20px;
	color:var(--titlecolor);
	background:var(--item-background);
	padding:5px 10px;
	z-index:1;
}
.explore_author_item:hover .explore_author_name
{
	display:block;
}

/* Author grid */
.grid_list 
{
	display:flex;
	flex-wrap: wrap;
	margin-left: -10px;
    margin-right: -10px;
}

/* Authors grid */
.author_item
{
	display:block;
	width:50%;
	color:var(--forecolor);
	font-size:0.9em;
}
.author_item:hover 
{
	text-decoration:none;
}
.author_item_content 
{
	display:flex;
	gap:20px;
	margin:10px;
	padding:10px 15px 20px 15px;
	background:var(--item-background);
	border-radius:10px;
	box-shadow:0 10px 15px -3px #0000004d;
}
.author_item:hover .author_picture
{
	transform:scale(1.1);
}
/* Author info */
.author_info
{
	display:flex;
	gap:30px;
	background:var(--item-background);
	padding:15px 20px 20px 20px;
	border-radius:10px;
	margin-bottom:30px;
	color:var(--forecolor);
}
.author_info_picture_wrapper
{
	width:125px;
	height:125px;
	position:relative;
	overflow: hidden;
	border-radius: 100%;
}
.author_data 
{
	flex:1;
	font-size:0.9em;
}
.author_picture 
{
	position:absolute;
	left:0;
	top:0;
	width:100%;
	height:100%;
	object-fit: cover;
	border-radius:100%;
	transition: all 0.25s;
}
.author_username 
{
	font-size:1.25em;
	color:var(--titlecolor);
}
.author_stats 
{
	display:flex;
	font-size:0.9em;
	gap:15px;
	margin-top:30px;
	line-height:1;
}
.author_stats_value 
{
	color:var(--titlecolor);
	padding-right:3px;
	font-weight: 600;
}
.author_media 
{
	flex:1;
	gap:10px;
	display:flex;
	justify-content: end;
}

.author_item .author_bio
{
	height:60px;
	overflow: hidden;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 3; 
	white-space: pre-wrap; 
}
/*
.nft_item
{
	padding: 0 10px 40px 10px;
	flex:1;
	text-decoration:none;
}*/

.item_info 
{
	background:var(--item-background);
	color:var(--item-color);
	display:flex;
	flex-direction:column;
	border-radius:0 0 10px 10px;
	text-decoration:none;
	box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.3);
	position:relative;
	cursor:pointer;
}
.item_padding 
{
	padding:0 15px 15px 15px;
}

.item_thumbnail_wrapper
{
	width:100%;
	padding-top:56.25%;
	position:relative;
	overflow: hidden;
	border-radius:5px 5px 0 0;
}
.item_thumbnail
{
	position:absolute;
	left:0;
	top:0;
	width:100%;
	height:100%;
	object-fit: cover;
	transition:all 0.5s;
	border-bottom:5px solid var(--type-0);
}

.explore_item:hover 
{
	text-decoration:none;
}

.explore_item:hover .item_thumbnail
{
	transform: scale(1.2);
}

.item_stats 
{
	display:flex;
	padding:15px 0 0 0;
	border-top:1px solid rgba(0,0,0,0.4);
}

.item_stats_item
{
	display:flex;
	font-size:0.7em;
	align-items:center;
	margin-left:10px;
	padding-right:10px;
	border-right:1px solid rgba(0,0,0,0.7);
}
.item_stats_item:first-child
{
	margin-left:0;
	flex:1;
	border-right:0;
	color:black;
}

.item_stats_item:last-child
{
	border-right:none;
	padding-right:0;
}
.item_stats svg 
{
	height:18px;
	fill:gray;
	margin-left:5px;
}
.item_title
{
	color:var(--titlecolor);
	flex:1;
	font-size:1.em;
	line-height:1.2;
	font-weight:600;
}
.item_description
{
	font-size:0.8em;
	line-height:1.3;
	color:inherit; /*var(--item-info-color);*/
}

.item_type 
{
	padding:2px 5px;
	border-radius:2px;
	background:var(--titlecolor);
}
/*
.item_type_0 {background:var(--type-0);}
.item_type_1 {background:var(--type-1);}
.item_type_2 {background:var(--type-2);}
.item_type_3 {background:var(--type-3);}
.item_type_4 {background:var(--type-4);}
.item_type_5 {background:var(--type-5);}
*/

.item_author 
{
	font-size:0.8em;
	margin-bottom:5px;
}

.item_author_link 
{
	cursor:pointer;
	color:var(--highlight);
	display:inline;
}
.item_author_link:hover
{
	text-decoration: underline;
}

.item_amount_sep
{
	margin:0 10px;
	acolor:gray;
}
.item_price 
{
	display:flex;
	align-items:baseline;
	acolor: var(--item-info-color);
	font-weight:600;
	font-size:1.25em;
	margin:10px 0;
	ajustify-content: flex-end;
}
.item_price .currency
{
	margin-left:5px;
	font-weight:600;
	line-height: 1.2;
	font-size:.5em;
	color:#888;
}
.item_price .currency svg
{
	width:12px;
	fill:#555;
}

.explore_item .row 
{
	gap:10px;
	margin-top:15px;
}
.explore_item .col 
{
	padding:0;
}
.item_picture_col 
{
	width:40px;
	height:40px;
}
.item_picture 
{
	width:100%;
	height:100%;
	object-fit: cover;
	border-radius: 50%;
}