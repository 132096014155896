img {
  display: none;
}

img[src] {
  display: block;
}

.content.explore {
  padding-top: 80px;
  display: flex;
}

.explore_left {
  min-width: 200px;
  margin-top: 30px;
  padding-right: 40px;
}

.explore_right {
  flex: 1;
  padding: 30px 0 90px;
}

.explore_filter {
  margin-bottom: 15px;
}

.explore_filter select {
  background: var(--background);
  padding: 0 0 5px;
}

.explore .filters_section {
  border-radius: 10px;
  gap: 20px;
  display: flex;
  position: relative;
}

.filters_section input, .filters_section select {
  background: var(--nav-background);
  color: var(--titlecolor);
  border: none;
  border-bottom: 1px solid var(--forecolor);
  margin-bottom: 0;
  padding: 4px 10px 4px 0;
}

.filter_col {
  amin-width: 200px;
}

.filter_col:first-child {
  flex: 1;
}

.items_section {
  amargin-top: 30px;
  amargin-bottom: 100px;
}

.items_grid {
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
  display: flex;
}

.explore_item {
  min-width: 33.33%;
  max-width: 33.33%;
  width: 33.33%;
  padding: 12px 10px;
}

.explore_authors {
  gap: 20px;
  margin-bottom: 30px;
  display: flex;
}

.explore_author_item {
  width: 70px;
  height: 70px;
  position: relative;
}

.explore_author_wrapper {
  width: 100%;
  background: var(--item-background);
  border-radius: 100%;
  padding-top: 100%;
  position: relative;
  overflow: hidden;
}

.explore_author_picture {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100%;
  transition: all .35s;
  position: absolute;
  top: 0;
  left: 0;
}

.explore_author_item:hover .explore_author_picture {
  transform: scale(1.1);
}

.explore_author_name {
  color: var(--titlecolor);
  background: var(--item-background);
  z-index: 1;
  padding: 5px 10px;
  font-size: .7em;
  display: none;
  position: absolute;
  top: -20px;
  left: 50%;
}

.explore_author_item:hover .explore_author_name {
  display: block;
}

.grid_list {
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
  display: flex;
}

.author_item {
  width: 50%;
  color: var(--forecolor);
  font-size: .9em;
  display: block;
}

.author_item:hover {
  text-decoration: none;
}

.author_item_content {
  background: var(--item-background);
  border-radius: 10px;
  gap: 20px;
  margin: 10px;
  padding: 10px 15px 20px;
  display: flex;
  box-shadow: 0 10px 15px -3px #0000004d;
}

.author_item:hover .author_picture {
  transform: scale(1.1);
}

.author_info {
  background: var(--item-background);
  color: var(--forecolor);
  border-radius: 10px;
  gap: 30px;
  margin-bottom: 30px;
  padding: 15px 20px 20px;
  display: flex;
}

.author_info_picture_wrapper {
  width: 125px;
  height: 125px;
  border-radius: 100%;
  position: relative;
  overflow: hidden;
}

.author_data {
  flex: 1;
  font-size: .9em;
}

.author_picture {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100%;
  transition: all .25s;
  position: absolute;
  top: 0;
  left: 0;
}

.author_username {
  color: var(--titlecolor);
  font-size: 1.25em;
}

.author_stats {
  gap: 15px;
  margin-top: 30px;
  font-size: .9em;
  line-height: 1;
  display: flex;
}

.author_stats_value {
  color: var(--titlecolor);
  padding-right: 3px;
  font-weight: 600;
}

.author_media {
  flex: 1;
  justify-content: end;
  gap: 10px;
  display: flex;
}

.author_item .author_bio {
  height: 60px;
  -webkit-line-clamp: 3;
  white-space: pre-wrap;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.item_info {
  background: var(--item-background);
  color: var(--item-color);
  cursor: pointer;
  border-radius: 0 0 10px 10px;
  flex-direction: column;
  text-decoration: none;
  display: flex;
  position: relative;
  box-shadow: 0 10px 15px -3px #0000004d;
}

.item_padding {
  padding: 0 15px 15px;
}

.item_thumbnail_wrapper {
  width: 100%;
  border-radius: 5px 5px 0 0;
  padding-top: 56.25%;
  position: relative;
  overflow: hidden;
}

.item_thumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-bottom: 5px solid var(--type-0);
  transition: all .5s;
  position: absolute;
  top: 0;
  left: 0;
}

.explore_item:hover {
  text-decoration: none;
}

.explore_item:hover .item_thumbnail {
  transform: scale(1.2);
}

.item_stats {
  border-top: 1px solid #0006;
  padding: 15px 0 0;
  display: flex;
}

.item_stats_item {
  border-right: 1px solid #000000b3;
  align-items: center;
  margin-left: 10px;
  padding-right: 10px;
  font-size: .7em;
  display: flex;
}

.item_stats_item:first-child {
  color: #000;
  border-right: 0;
  flex: 1;
  margin-left: 0;
}

.item_stats_item:last-child {
  border-right: none;
  padding-right: 0;
}

.item_stats svg {
  height: 18px;
  fill: gray;
  margin-left: 5px;
}

.item_title {
  color: var(--titlecolor);
  font-size: 1. em;
  flex: 1;
  font-weight: 600;
  line-height: 1.2;
}

.item_description {
  color: inherit;
  font-size: .8em;
  line-height: 1.3;
}

.item_type {
  background: var(--titlecolor);
  border-radius: 2px;
  padding: 2px 5px;
}

.item_author {
  margin-bottom: 5px;
  font-size: .8em;
}

.item_author_link {
  cursor: pointer;
  color: var(--highlight);
  display: inline;
}

.item_author_link:hover {
  text-decoration: underline;
}

.item_amount_sep {
  acolor: gray;
  margin: 0 10px;
}

.item_price {
  acolor: var(--item-info-color);
  ajustify-content: flex-end;
  align-items: baseline;
  margin: 10px 0;
  font-size: 1.25em;
  font-weight: 600;
  display: flex;
}

.item_price .currency {
  color: #888;
  margin-left: 5px;
  font-size: .5em;
  font-weight: 600;
  line-height: 1.2;
}

.item_price .currency svg {
  width: 12px;
  fill: #555;
}

.explore_item .row {
  gap: 10px;
  margin-top: 15px;
}

.explore_item .col {
  padding: 0;
}

.item_picture_col {
  width: 40px;
  height: 40px;
}

.item_picture {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

/*# sourceMappingURL=index.bfa58446.css.map */
